const Header = () =>{
  return (
    <div className="header-block">
        <div className="header">
            <p className="header-item"><a href='#dried-fruits'>Сухофрукты</a></p>
            <p className="header-item"><a href='#nuts'>Орехи</a></p>
            <p className="header-item"><a href='#candy'>Конфеты</a></p>
            <p className="header-item"><a href='#kzproduct'>Продукция из Казахстана</a></p>
        </div>
        <hr></hr>
    </div>
  );
}
  
export default Header;