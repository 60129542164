import Fade from 'react-reveal/Fade'

const Nuts = () =>{
  return (
    <div id='nuts' className='nuts'>
        <div className='nuts-text' itemScope itemType='http://schema.org/Product'>
          <Fade left>
            <h2 itemProp='name'>Орехи в Калининграде</h2>
            <p itemProp='description'>Свежие, натуральные орехи<br></br> из Армении, Бразилии и Узбекистана.<br></br> Большой ассортимент, более 20 видов.</p>
          </Fade>
        </div>
        <img src='img/3.jpg' className='nuts-img'></img>
    </div>
    
  );
}
  
export default Nuts;