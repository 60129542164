import Bounce from 'react-reveal/Bounce'

const Candy = () =>{
  return (
    <div id='candy' className='candy'>
        <div itemScope itemType='http://schema.org/Product' className='candy-text'>
          <Bounce>
              <h2 itemProp='name'>Конфеты в Калининграде</h2>
              <p itemProp='description'>Свежие конфеты из разных фабрик России. Низкие цены, частые акции и скидки 
                  <br></br>из-за прямых поставок без посредников. Большой ассортимент, 
                  <br></br>более 400 видов конфет.</p>
          </Bounce>
        </div>
        <img className='candy-img' src='img/1.png'></img>
    </div>
    
  );
}
  
export default Candy;