import Swiper from './Swiper';

const DriedFruits = () =>{
  return (
    <div id='dried-fruits' className='dried-fruits' itemScope itemType='http://schema.org/Product'>
        <h2 itemProp='name'>Сухофрукты из Армении в Калининграде</h2>
        <p itemProp='description'>Натуральные сухофрукты из Армении без сахара. Прямые поставки без посредников, более 30 видов.</p>
        <div style={{width:'90%', paddingLeft:'10%'}}>
          <Swiper/>
        </div>
    </div>
  );
}
  
export default DriedFruits;