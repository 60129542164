import Fade from 'react-reveal/Fade'

const KzProduct = () =>{
  return (
    <div id='kzproduct' className='kzproduct'>
        <div className='kzproduct-text' itemScope itemType='http://schema.org/Product'>
          <Fade left>
            <h2 itemProp='name'>Продукты из Казахстана в Калининграде</h2>
            <p itemProp='description'>Вафли, чай, шоколад и конфеты продукции Рахат и Баян Сулу.
            <br></br>Макаронные изделия, томатные пасты, рис, мука и другие продукты из Казахстана.</p>
          </Fade>
        </div>
        <img src='img/4.png' className='kzproduct-img'></img>
    </div>
    
  );
}
  
export default KzProduct;