import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Swiper = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    dots: false
  };

  return (
    <Slider {...settings}>
            <div>
                <img src='img/5.jpg' style={{width:'90%'}}></img>
            </div>
            <div>
                <img src='img/6.jpg' style={{width:'90%'}}></img>
            </div>
            <div>
                <img src='img/15.jpg' style={{width:'90%'}}></img>
            </div>
            <div>
                <img src='img/16.jpg' style={{width:'90%'}}></img>
            </div>
            <div>
                <img src='img/17.jpg' style={{width:'90%'}}></img>
            </div>
            <div>
                <img src='img/18.jpg' style={{width:'90%'}}></img>
            </div>
            <div>
                <img src='img/19.jpg' style={{width:'90%'}}></img>
            </div>
            <div>
                <img src='img/20.jpg' style={{width:'90%'}}></img>
            </div>
            <div>
                <img src='img/21.jpg' style={{width:'90%'}}></img>
            </div>
            <div>
                <img src='img/22.jpg' style={{width:'90%'}}></img>
            </div>
            <div>
                <img src='img/23.jpg' style={{width:'90%'}}></img>
            </div>
            <div>
                <img src='img/24.jpg' style={{width:'90%'}}></img>
            </div>
            <div>
                <img src='img/25.jpg' style={{width:'90%'}}></img>
            </div>
            <div>
                <img src='img/13.jpg' style={{width:'90%'}}></img>
            </div>
            <div>
                <img src='img/7.jpg' style={{width:'90%'}}></img>
            </div>
            <div>
                <img src='img/8.jpg' style={{width:'90%'}}></img>
            </div>
            <div>
                <img src='img/9.jpg' style={{width:'90%'}}></img>
            </div>
            <div>
                <img src='img/10.jpg' style={{width:'90%'}}></img>
            </div>
            <div>
                <img src='img/11.jpg' style={{width:'90%'}}></img>
            </div>
            <div>
                <img src='img/12.jpg' style={{width:'90%'}}></img>
            </div>
            <div>
                <img src='img/14.jpg' style={{width:'90%'}}></img>
            </div>
    </Slider>
  );
};
  
export default Swiper;