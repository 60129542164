import React from 'react';
import Header from './components/Header';
import Title from './components/Title';
import DriedFruits from './components/Dried-Fruits';
import Nuts from './components/Nuts';
import Candy from './components/Candy';
import KzProduct from './components/KzProduct';
import Questions from './components/Questions';
import Footer from './components/Footer';
import ErrorBoundary from './components/ErrorBoundary'
import './style/index.css';


const App = () =>{
  return (
    <div className='App'>
      <ErrorBoundary>
        <Header/>
        <Title/>
        <DriedFruits/>
        <Nuts/>
        <Candy/>
        <KzProduct/>
        <Questions/>
        <Footer/>
      </ErrorBoundary>
     </div>
  );
}
  
export default App;