import Fade from 'react-reveal/Fade'

const Title = () =>{
  return (
        <div className='title'>
            <Fade top><h1 className="h1">Сладкое царство</h1></Fade>
            <Fade left><h2 className="h2">Магазин конфет, орехов,<br></br> сухофруктов из Армении,<br></br> продуктов из Казахстана.<br></br>
                Оптовые цены на все товары.</h2></Fade>
        </div>
  );
}
  
export default Title;