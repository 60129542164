const Footer = () =>{
  return (
    <div id='footer' className='footer'>
        <div style={{borderBottom:'1px solid'}}></div>
        <p style={{paddingTop:'50px'}}>ул. Крымская 8,<br></br> Калининград, Калининградская обл.</p>
        <p>ул. Денисова 22,<br></br> Калининград, Калининградская обл.</p>
        <p>+79062396866</p>
        <a href="https://instagram.com/sladkoetsarstvo_39">sladkoetsarstvo_39</a>
    </div>
  );
}
  
export default Footer;