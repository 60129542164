import Fade from 'react-reveal/Fade'
import Accordion from 'react-bootstrap/Accordion';

const Questions = () =>{
    return (
        <div id='questions' className='questions'>
            <Fade><h2 style={{textAlign:'center', paddingBottom: '20px'}}>Вопросы и ответы</h2></Fade>
            <Accordion>
    
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Когда работает магазин?</Accordion.Header>
                    <Accordion.Body>
                    Магазин по адресу ул. Крымская 8, г.Калининград (в магазине SPAR) работает от 10:30 до 20:30(без выходных).<br></br>
                    Магазин по адресу ул.Денисова 22, г.Калининград работает от 10:00 до 20:00 (без выходных).
                    </Accordion.Body>
                </Accordion.Item>
    
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Где находится ваши магазины?</Accordion.Header>
                    <Accordion.Body>
                    Адреса:<br></br>
                        ул. Крымская 8, г.Калининград (в магазине SPAR)<br></br>
                        ул. Денисова 22, г.Калининград
                    </Accordion.Body>
                </Accordion.Item>
    
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Дорогие ли у вас продукты?</Accordion.Header>
                    <Accordion.Body>
                    Относительно других магазинов у нас очень низкие цены, так как мы сами делаем поставки без посредников.
                    </Accordion.Body>
                </Accordion.Item>
    
                <Accordion.Item eventKey="3">
                    <Accordion.Header>Как получить скидку?</Accordion.Header>
                    <Accordion.Body>
                    Если вы делаете покупку от 1000 руб в нашем магазине, то получаете скидку 5%, сказав промокод.<br></br>Промокод: МАРЦИПАН
                    </Accordion.Body>
                </Accordion.Item>
            
                <Accordion.Item eventKey="5">
                    <Accordion.Header>Перейди если нет вашего вопроса</Accordion.Header>
                    <Accordion.Body>
                    Напишите ваш вопрос на почту sladkoe.tsarstvo.39@mail.ru и получите ваш ответ.
                    </Accordion.Body>
                </Accordion.Item>
    
            </Accordion>
        </div>
      );
}
  
export default Questions;

